<template>

  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0">สั่งซื้อ วันนี้</h3>
        </b-col>
        <!-- <b-col class="text-right">
          <a href="#!" class="btn btn-sm btn-primary">See all</a>
        </b-col> -->
      </b-row>
    </template>

    <el-table class="table-responsive table"
              :data="tableData"
              header-row-class-name="thead-light">
      <el-table-column label="รหัสการสั่งซื้อ"
                       min-width="90"
                       prop="page"
                       align="center">
        <template v-slot="{row}">
          <div class="font-weight-600">{{row.page}}</div>
        </template>
      </el-table-column>
      <el-table-column label="ชื่อลูกค้า"
                       min-width="130px"
                       prop="visitors">
      </el-table-column>
      <el-table-column label="จำนวนเงิน"
                       min-width="90px"
                       prop="unique">
      </el-table-column>

      <el-table-column label="จัดส่ง"
                       min-width="90px"
                       prop="bounceRate">
        <template v-slot="{row}">
          {{row.bounceRate}}
        </template>
      </el-table-column>
    </el-table>
  </b-card>
</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'page-visits-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    props: ["tableData"],
    data() {
      return {
        // tableData: [
        //   {
        //     page: 1,
        //     visitors: 'นางสัญญา',
        //     unique: '4,569',
        //     bounceRate: 'สามย่าน'
        //   },
        //   {
        //     page: 2,
        //     visitors: 'นายจำนน',
        //     unique: '3,985',
        //     bounceRate: 'สามย่าน'
        //   },
        //   {
        //     page: 3,
        //     visitors: 'น.ส.ศรีสมร',
        //     unique: '3,513',
        //     bounceRate: 'หนองเขม'
        //   },
        //   {
        //     page: 4,
        //     visitors: 'นายเปรียม',
        //     unique: '2,050',
        //     bounceRate: '50,87%'
        //   },
        //   {
        //     page: 5,
        //     visitors: 'นายไฉไล',
        //     unique: '1,795',
        //     bounceRate: 'สามย่าน'
        //   }
        // ]
      }
    }
  }
</script>
<style>
</style>
