<template>
  <div>
    <!-- Header -->
    <div class="header bg-red py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">Appbit web application.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#f8f9fe" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-1 border-gray-300 mb-0 shadow-2">
            <!-- <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5 custom-card">
              <div class="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <div class="row mb-4">
                    <div class="col-12">
                      <base-input alternative
                                  class="mb-3"
                                  name="Username"
                                  :rules="{required: true}" :autofocus="true"
                                  prepend-icon="ni ni-circle-08"
                                  placeholder="Username"
                                  v-model="model.username"
                                  inputClasses="form_control_not">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <base-input alternative
                                  class="mt-3"
                                  name="Password"
                                  :rules="{required: true, min: 6}"
                                  prepend-icon="ni ni-lock-circle-open"
                                  type="password"
                                  placeholder="Password"
                                  v-model="model.password"
                                  inputClasses="form_control_not">
                      </base-input>
                    </div>
                  </div>

                  <!-- <b-form-checkbox v-model="model.rememberMe">Remember me</b-form-checkbox> -->
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <!-- <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import HttpServices from '../../services/httpServices';
import SessionStorageUtils from '../../util/sessionStorageUtils';
  export default {
    data() {
      return {
        model: {
          username: '',
          password: '',
          rememberMe: false
        }
      };
    },
    methods: {
      async onSubmit() {
        const response = await HttpServices.postAuthenData("/auth", this.model);
        if(response.status&&response.status.code=="200"){
          SessionStorageUtils.setUser(response.data);
          this.$router.push('/dashboard');
        }else{
          this.AlertUtils.alert("warning", response.status.message);
        }
        // this will be called only after form is valid. You can do api call here to login
      }
    }
  };
</script>
<style>
  .form-group{
    margin-bottom: 15px;
  }
</style>
