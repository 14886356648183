<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0">ร้านค้า</h3>
        </b-col>
        <!-- <b-col class="text-right">
          <base-button size="sm" type="primary">See all</base-button>
        </b-col> -->
      </b-row>
    </template>


    <el-table
      class="table-responsive table"
      :data="tableData"
      header-row-class-name="thead-light">
      <el-table-column label="ชื่อร้านค้า" min-width="150px" prop="name">
        <template v-slot="{row}">
          <div class="font-weight-600">{{row.name}}</div>
        </template>
      </el-table-column>

      <el-table-column label="ซัพพลายเออร์ที่ผูกกับร้านค้า" min-width="250px" prop="visitors" align="center">
      </el-table-column>

      <el-table-column min-width="220px" prop="progress" label="สินค้าที่ผูกกับร้านค้า">
        <template v-slot="{row}">
          <div class="d-flex align-items-center">
            <span class="mr-2">{{row.progress}}%</span>
            <base-progress :type="row.progressType" :value="row.progress" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </b-card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'social-traffic-table',
    components: {
      BaseProgress,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    props: ["tableData"],
    data() {
      return {
        // tableData: [
        //   {
        //     name: 'หรีด ณ วัด',
        //     visitors: '1',
        //     progress: 60,
        //     progressType: 'gradient-danger',
        //   },
        //   {
        //     name: 'หรีดมาลา',
        //     visitors: '5',
        //     progress: 70,
        //     progressType: 'gradient-success',
        //   },
        //   {
        //     name: 'หรีดสเตชั่น',
        //     visitors: '4',
        //     progress: 80,
        //     progressType: 'gradient-primary',
        //   },
        //   {
        //     name: 'เลอหรีด',
        //     visitors: '3',
        //     progress: 75,
        //     progressType: 'gradient-info',
        //   },
        //   {
        //     name: 'Love You Flower',
        //     visitors: '2',
        //     progress: 30,
        //     progressType: 'gradient-warning',
        //   }
        // ]
      }
    }

  }
</script>
<style>
</style>
