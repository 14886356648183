<template>
  <div>

    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
      <b-row>
        <div class="col-12">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="mb-4">
                <label class="form-label">ร้านค้า</label>
                <select class="form-control"
                        v-model="store_id"
                        @change="searchDataHandler()">
                  <option v-for="(store, index) in item_stores"
                          :key="`dashboard_store_${index}`"
                          :value="store.id">
                          {{store.text}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <b-col xl="3" md="6">
          <stats-card title="จำนวนสั่งซื้อ"
                      type="gradient-red"
                      :sub-title="countData.orderStore.toString()"
                      icon="fa fa-product-hunt"
                      class="mb-4">
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="จำนวนลูกค้า"
                      type="gradient-orange"
                      :sub-title="countData.costumerStore.toString()"
                      icon="ni ni-chart-pie-35"
                      class="mb-4">
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="จำนวนผู้ผลิต"
                      type="gradient-green"
                      :sub-title="countData.supplierStore.toString()"
                      icon="ni ni-money-coins"
                      class="mb-4">
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="จำนวนกลุ่มผู้ใช้"
                      type="gradient-info"
                      :sub-title="countData.UsersGroup.toString()"
                      icon="ni ni-chart-bar-32"
                      class="mb-4">
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col xl="12" class="mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">{{title}}</h5>
              </b-col>
              <b-col>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-4">
                          <label class="form-label text-white">ตั้งแต่วันที่</label>
                          <date-picker  :format="'DD/MM/YYYY'"
                                        v-model="start"
                                        class="w-100"
                                        input-class="form-control form-control-sm"
                                        placeholder="ตั้งแต่วันที่"
                                        :disabled-date="disableStartDate"
                                        :append-to-body="false"
                                        :popup-style="{left: 0}" @change="searchDataHandler()"></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-4">
                          <label class="form-label text-white">ถึง</label>
                          <date-picker  :format="'DD/MM/YYYY'"
                                        v-model="end"
                                        class="w-100"
                                        input-class="form-control form-control-sm"
                                        placeholder="ถึง"
                                        :disabled-date="disabledEndDate"
                                        :append-to-body="false"
                                        :popup-style="{left: 0}" @change="searchDataHandler()"></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <b-nav class="nav-pills justify-content-end">
                  <b-nav-item
                       class="mr-2 mr-md-0"
                       :active="bigLineChart.activeIndex === 0"
                       link-classes="py-2 px-3"
                       @click.prevent="initBigChart(0, 'สั่งซื้อ')">
                      <span class="d-none d-md-block">สั่งซื้อ</span>
                      <span class="d-md-none">M</span>
                  </b-nav-item>
                  <b-nav-item
                    link-classes="py-2 px-3"
                    :active="bigLineChart.activeIndex === 1"
                    @click.prevent="initBigChart(1, 'จัดส่ง')"
                  >
                    <span class="d-none d-md-block">จัดส่ง</span>
                    <span class="d-md-none">W</span>
                  </b-nav-item>
                </b-nav> -->
              </b-col>
            </b-row>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </card>
        </b-col>

        <!-- <b-col xl="4" class="mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </b-col>
            </b-row>

            <bar-chart
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
        </b-col> -->
      </b-row>
      <!-- End charts-->

      <!--Tables-->
      <b-row class="mt-5">
        <b-col xl="8" class="mb-5 mb-xl-0">
          <page-visits-table :tableData="todayData"></page-visits-table>
        </b-col>
        <b-col xl="4" class="mb-5 mb-xl-0">
          <social-traffic-table :tableData="storeData"></social-traffic-table>
        </b-col>
      </b-row>
      <!--End tables-->
    </b-container>

  </div>
</template>
<script>
  import config from '../util/config';

  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import StatsCard from '@/components/Cards/StatsCard';

  // Tables
  import SocialTrafficTable from './Dashboard/SocialTrafficTable';
  import PageVisitsTable from './Dashboard/PageVisitsTable';
import DateUtils from '../util/dateUtils';
import NumberUtils from '../util/numberUtils';
  export default {
    data() {
      return {
        dataInterval: null,
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: ""
        },
        paging: {
          page: 1,
          limit: config.pagination.limit,
          pages: config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0
        },
        products: [],
        customers: [],
        suppliers: [],
        groups: [],
        countData: {
          UsersGroup: 0,
          costumerStore: 0,
          orderStore: 0,
          supplierStore: 0
        },
        todayData: [],
        storeData: [],
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          },
          extraOptions: chartConfigs.blueChartOptions
        },
        title: 'สั่งซื้อ',
        item_stores: [{
          id: 1,
          name: 'หรีด ณ วัด'
        }, {
          id: 2,
          name: 'หรีดมาลา'
        }, {
          id: 3,
          name: 'หรีดสเตชั่น'
        }],
        store_id: '',
        start: '',
        end: ''
      };
    },
    methods: {
      async searchDataHandler(){
        await this.getCountData();
        await this.getOrderProduct();
        await this.getOrderCurrentDay();
        await this.getTotalOrderStore();
      },
      async getCountData () {
        const params = `storeId=${this.store_id}`;
        const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/getCountDashboard?${params}`);
        this.countData = result;
      },
      async getOrderProduct () {
        const dateFrom = DateUtils.dateFormat(this.start, "YYYY-MM-DD");
        const dateTo = DateUtils.dateFormat(this.end, "YYYY-MM-DD");
        const params = `storeId=${this.store_id}&dateFrom=${dateFrom?dateFrom:''}&dateTo=${dateTo?dateTo:''}`;
        const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/orderProduct?${params}`);
        this.initBigChart(0, this.title, result.orders);
      },
      async getOrderCurrentDay () {
        const params = `storeId=${this.store_id}`;
        const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/orderCurrentDay?${params}`);
        const orderCurrentDay = result.orderCurrentDay;
        this.todayData = [];
        orderCurrentDay.forEach(v=>{
          this.todayData.push({
            page: v.code,
            visitors: v.customerName,
            unique: NumberUtils.toDecimalFormat(v.total),
            bounceRate: v.delivery_address
          });
        });
      },
      async getTotalOrderStore () {
        const params = `storeId=${this.store_id}`;
        const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/store?${params}`);
        const stores = result.stores;
        this.storeData = [];
        stores.forEach(v=>{
          let progressType = "gradient-danger"
          if(v.perProductOfStore>50&&v.perProductOfStore<60){
            progressType = "gradient-warning";
          }else if(v.perProductOfStore>60&&v.perProductOfStore<70){
            progressType = "gradient-yellow";
          }else if(v.perProductOfStore>70&&v.perProductOfStore<80){
            progressType = "gradient-info";
          }else if(v.perProductOfStore>80&&v.perProductOfStore<90){
            progressType = "gradient-primary";
          }else if(v.perProductOfStore>90){
            progressType = "gradient-success";
          }
          this.storeData.push({
            name: v.name,
            visitors: v.supplier,
            progress: parseFloat(v.perProductOfStore),
            progressType: progressType,
          });
        });
      },
      initBigChart(index, title, data) {
        this.title = title;
        const resultData = [];
        const labels = [];
        data.forEach(v=>{
          resultData.push(v.quantity);
          labels.push(v.name);
        });
        let chartData = {
          datasets: [
            {
              label: 'จำนวน',
              data: resultData//this.bigLineChart.allData[index]
            }
          ],
          labels: labels//['บุษปวัน', 'กุหลาบ', 'พวงหรีดดอกไม้สด', 'พวงหรีดพัดลม', 'พวงหรีดผ้า', 'พวงหรีดต้นไม้', 'พวงหรีดนาฬิกา', 'พวงหรีดดอกไม้ประดิษฐ์'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      },
      disableStartDate(date) {
        if(this.end){
          return date > this.end;
        }
        return false;
      },
      disabledEndDate(date){
        if(this.start){
          return date < this.start;
        }
        return false;
      }
    },
    beforeDestroy () {
      if(this.dataInterval){
        clearInterval(this.dataInterval);
      }
    },
    async mounted() {
      const result = await this.HttpServices.getMasterData(`/master/getStores`);
      this.item_stores = result;
      if(this.item_stores.length>0){
        this.store_id = this.item_stores[0].id;
      }
      await this.searchDataHandler();
      this.dataInterval = setInterval(() => {
        this.searchDataHandler();
      }, 180000);
    },
    components: {
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      PageVisitsTable,
      SocialTrafficTable
    }
  };
</script>
<style>
.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}
</style>
